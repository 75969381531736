import { useState, useEffect, useRef } from "react"; // Include useRef
import axios from 'axios';
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // Include your custom CSS

function App() {
  const [imageBlob, setImageBlob] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const isGeneratingImage = useRef(false);
  const [jobId, setJobId] = useState(null); // Add state to store Job ID
  const [apiStatus, setApiStatus] = useState(null);  // Add this line near your other useState declarations
  const myAPIKey = process.env.REACT_APP_MY_API_KEY;
  const [jobIds, setJobIds] = useState([]); // Changed to an array to store multiple job IDs
  const [generatedImages, setGeneratedImages] = useState([]); // Store generated image URLs
  // State for current generation images and gallery images
  const [currentImages, setCurrentImages] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [jobIndexMap, setJobIndexMap] = useState({});

  const createImageMetadata = (url, prompt, traits) => ({
    url,
    prompt,
    traits,
  });


  const [numberOfImages, setNumberOfImages] = useState(1);

  const [customPrompt, setCustomPrompt] = useState('ecld style, a beautiful surreal intriguing film still from the sci-fi movie ecld, 8k uhd, showing a schocking scene in a {trait2}. Analog style. (high detailed skin_1.1), photo realistic, (cinematic, film grain)');
  const [customNegativePrompt, setCustomNegativePrompt] = useState('');

  const [settingsJson, setSettingsJson] = useState(`{
    "override_settings": {
      "sd_model_checkpoint": "ecld_train4_last"
    },
    "override_settings_restore_afterwards": true,
    "seed": -1,
    "batch_size": 1,
    "steps": 30,
    "cfg_scale": 7,
    "hr_scale": 2,
    "enable_hr": false,
    "hr_second_pass_steps": 20,
    "hr_upscaler": "Latent",
    "denoising_strength": 0.4,
    "width": 1536,
    "height": 1024,
    "sampler_name": "DPM2 Karras",
    "sampler_index": "DPM2 Karras",
    "restore_faces": false
  }`);

  const descriptions = [
    { trait: '', probability: 0.3 },
    { trait: 'japanese', probability: 0.2 },
    { trait: 'black african', probability: 0.2 },
    { trait: 'mexican', probability: 0.2 },
    { trait: 'smiling', probability: 0.1 },
    { trait: 'indigenous', probability: 0.2 },

    // You can add more traits with their respective probabilities here
  ];

  const places = [
    { trait: '', probability: 0.6 },
    { trait: 'in the jungle', probability: 0.1 },
    { trait: 'in a desert', probability: 0.1 },
    { trait: 'in a spaceship', probability: 0.1 },
    { trait: 'in an artists workplace', probability: 0.1 },
    // You can add more traits with their respective probabilities here
  ];



  const genres = [
    { trait: '', probability: 0.5 },  // default genre with higher probability
    { trait: 'Science-fiction Cyberpunk', probability: 0.2 },
    { trait: 'horror', probability: 0.1 },
    { trait: 'Science-fiction', probability: 0.2 },
    // You can add more genres with their respective probabilities here
  ];

  function formatTraitsForInput(traitsArray) {
    return traitsArray
      .map((trait) => `${trait.trait}: ${trait.probability}`)
      .join(",\n");
  }

  function parseTraitsFromInput(inputString) {
    return inputString
      .split(",")
      .map((line) => {
        const [trait, probability] = line.split(":").map((str) => str.trim());
        return { trait, probability: parseFloat(probability) };
      });
  }


  // Use this function to format the default text for the traits text boxes
  const defaultDescriptionsText = formatTraitsForInput(descriptions);
  const defaultPlacesText = formatTraitsForInput(places);
  const defaultGenresText = formatTraitsForInput(genres);

  const [traits1, setTraits1] = useState(defaultDescriptionsText);
  const [traits2, setTraits2] = useState(defaultPlacesText);
  const [traits3, setTraits3] = useState(defaultGenresText);


  function selectTrait(traits) {
    const random = Math.random();
    let sum = 0;

    for (let i = 0; i < traits.length; i++) {
      sum += traits[i].probability;
      if (random <= sum) {
        return traits[i].trait;
      }
    }

    return '';  // default if no trait matches, though this should be rare due to probabilities
  }

  // CHECK JOB FUNCTION ************************

  const checkJobStatus = async (id) => {
    try {
      const response = await axios.get(`https://api.runpod.ai/v2/fzl2bpiqq4yna7/status/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${myAPIKey}`
        },
      });

      console.log(`Received status update for job ID ${id}: `, response.data);

      if (response.data && response.data.status) {
        setApiStatus(response.data.status); // Update the API status
      }

      if (response.data && response.data.output && response.data.output.images) {
        const imageBytes = atob(response.data.output.images[0]); // Assuming images is an array and you're interested in the first image
        const bytes = new Uint8Array(imageBytes.length);
        for (let i = 0; i < imageBytes.length; i++) {
          bytes[i] = imageBytes.charCodeAt(i);
        }
        const blob = new Blob([bytes.buffer], { type: 'image/png' });
        const url = URL.createObjectURL(blob);

        const imageIndex = jobIndexMap[id]; // Get the index from the job index map
        if (imageIndex !== undefined) {
          setCurrentImages(prevImages => {
            const updatedImages = [...prevImages];
            updatedImages[imageIndex] = url; // Replace placeholder with actual image
            return updatedImages;
          });
        }
        setJobIds((prevJobIds) => prevJobIds.filter(jobId => jobId !== id)); // Remove completed job ID from jobIds
      }
    } catch (err) {
      console.error(err);
    }
  };



  // GENERATE ART FUNCTION ************************

  const generateArt = async () => {
    setIsLoading(true);
    isGeneratingImage.current = true; // Set isGeneratingImage to true

    // First, move current images to the gallery
    setGalleryImages((prevGallery) => [...prevGallery, ...currentImages]);

    // Set placeholders for the number of images being generated

    const placeholders = Array(numberOfImages).fill(`${process.env.PUBLIC_URL}/placeholder.png`);
    setCurrentImages(placeholders);

    const newJobIds = [];
    const newJobIndexMap = { ...jobIndexMap }; // Clone the current map

    const parsedTraits1 = parseTraitsFromInput(traits1);
    const parsedTraits2 = parseTraitsFromInput(traits2);
    const parsedTraits3 = parseTraitsFromInput(traits3);

    for (let i = 0; i < numberOfImages; i++) {
      const trait1 = selectTrait(parsedTraits1);
      const trait2 = selectTrait(parsedTraits2);
      const trait3 = selectTrait(parsedTraits3);

      const promptText = customPrompt
        .replace("{trait1}", trait1)
        .replace("{trait2}", trait2)
        .replace("{trait3}", trait3);

      const negativePromptText = customNegativePrompt || "(clone, siamese twins, worst quality, low quality, illustration, 3d, 2d, painting, cartoons, sketch, deformed)";

      let settings = {};
      try {
        settings = JSON.parse(settingsJson);
      } catch (error) {
        console.error("Failed to parse settings JSON:", error);
        return;
      }

      console.log("Selected Trait1:", trait1);
      console.log("Selected Trait2:", trait2);
      console.log("Selected Trait3:", trait3);
      console.log("Generated Prompt:", promptText);

      try {
        console.log("Sending request to API");
        const payload = {
          "input": {
            "api": {
              "method": "POST",
              "endpoint": "/sdapi/v1/txt2img"
            },
            "payload": {
              ...settings,
              "prompt": promptText,
              "negative_prompt": negativePromptText
            }
          }
        };

        const response = await axios.post(
          `https://api.runpod.ai/v2/fzl2bpiqq4yna7/run`,
          payload,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${myAPIKey}`
            },
          }
        );

        console.log("Received response from API", response.data);

        if (response.data && response.data.id) {
          newJobIds.push(response.data.id);
          newJobIndexMap[response.data.id] = i; // Map job ID to the current index
        }
      } catch (err) {
        console.error(err);
      }
    }

    setJobIds(newJobIds); // Update the jobIds state with new job IDs
    setJobIndexMap(newJobIndexMap); // Update the job index map
    setCurrentImages(placeholders); // Set the placeholders

    setIsLoading(false);
    isGeneratingImage.current = false;
  };


  useEffect(() => {
    const interval = setInterval(async () => {
      await Promise.all(
        jobIds.map(async (id) => {
          if (id) {
            await checkJobStatus(id);
          }
        })
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [jobIds]); // Depend on jobIds


  // ACTUAL PAGE ************************

  return (
    <div className="container my-5">
      <h1 className="text-center display-4 mb-4">AI art playground</h1>
      <div className="d-flex flex-column align-items-center">
        {/* Flex container for Generate button and Number of Images Selector */}
        <div className="d-flex align-items-center mb-4">
          <button onClick={generateArt} className="btn btn-dark btn-lg me-2">Generate</button>
          <select
            value={numberOfImages}
            onChange={(e) => setNumberOfImages(Number(e.target.value))}
            className="form-select form-select-lg"  // Removed mb-3 to align with the button
          >
            {[...Array(10).keys()].map((number) => (
              <option key={number} value={number + 1}>
                {number + 1}
              </option>
            ))}
          </select>
        </div>

        <div className="image-grid mb-3">
          {currentImages.map((src, index) => (
            <img key={index} src={src} alt={`Current Art ${index + 1}`} className={src === `${process.env.PUBLIC_URL}/placeholder.png` ? "placeholder-image" : "generated-image"} />
          ))}
        </div>

        {isLoading && apiStatus && (
          <div className="status-text-container">
            <p>{`Status: ${apiStatus}`}</p>
          </div>
        )}
        <div className="w-75">
          <h2 className="text-center mb-3">Settings</h2>
          <form className="mb-3">
            <div className="form-group">
              <textarea
                className="form-control"
                placeholder="Enter custom prompt"
                value={customPrompt}
                onChange={(e) => setCustomPrompt(e.target.value)}
                rows="3"
              ></textarea>
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                placeholder="Enter custom negative prompt"
                value={customNegativePrompt}
                onChange={(e) => setCustomNegativePrompt(e.target.value)}
                rows="3"
              ></textarea>
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                placeholder="Enter settings JSON"
                value={settingsJson}
                onChange={(e) => setSettingsJson(e.target.value)}
                rows="6"
              ></textarea>
            </div>

            <div className="input-group mb-3">
              <span className="input-group-text">Trait1</span>
              <textarea
                className="form-control"
                value={traits1}
                onChange={(e) => setTraits1(e.target.value)}
                rows="3"
              ></textarea>
            </div>

            <div className="input-group mb-3">
              <span className="input-group-text">Trait2</span>
              <textarea
                className="form-control"
                value={traits2}
                onChange={(e) => setTraits2(e.target.value)}
                rows="3"
              ></textarea>
            </div>

            <div className="input-group mb-3">
              <span className="input-group-text">Trait3</span>
              <textarea
                className="form-control"
                value={traits3}
                onChange={(e) => setTraits3(e.target.value)}
                rows="3"
              ></textarea>
            </div>


          </form>
        </div>
        <h2 className="text-center display-6 mb-3">Gallery</h2>
        <div className="gallery-grid">
          {galleryImages.map((src, index) => (
            <img key={index} src={src} alt={`Gallery Art ${index + 1}`} className="generated-image" />
          ))}
        </div>
      </div>
    </div>
  );

}

export default App;
